import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { BuildingImage } from '../models/building.model';
import { KeyValueItem } from '../models/keyValueItem.model';
import { EditPole, PoleDetails } from '../models/pole.model';
import { PaginationDto, ResponsePackage } from '../models/util.model';
import { AssetStatusEnum } from '../models/assetStatus.model';

@Injectable({
  providedIn: 'root'
})
export class PoleService {

  private pathApi = this.config.setting['pathApi'] + "pole/";
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient, private config: AppConfig) {}

  getAll(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<PoleDetails>>>(`${this.pathApi}getAll`, obj, {headers: this.headers, observe: 'response'});
  }
  getAllRejectionReports(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<PoleDetails>>>(`${this.pathApi}getAllRejectionReports`, obj, {headers: this.headers, observe: 'response'});
  }
  recall(id: number) {
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}recall/${id}`,{headers: this.headers, observe: 'response'});
  }
  getById(id: number) {
    return this.http.get<ResponsePackage<EditPole>>(`${this.pathApi}getById?id=${id}`,{headers: this.headers, observe: 'response'});
  }

  uploadPicture(obj: any, image: File){  
    let formData: FormData = new FormData();
    formData.append('entityId', obj.poleId);
    formData.append('imageType', obj.imageType);
    formData.append('image', image, image.name);

    return this.http.post<ResponsePackage<BuildingImage>>(`${this.pathApi}uploadPicture`, formData, {observe: 'response'});
  }
  
  addOrUpdatePole(obj: any) {
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}addOrUpdate`, obj, {headers: this.headers, observe: 'response'});
  }

  getPoleTypesForOptions(): Observable<any> {
    return this.http.get(`${this.pathApi}getPoleTypesForOptions`);
  }

  assignPole(poleId: number, userId: number, assetStatus: AssetStatusEnum,isFromRejection:boolean ) {
    const obj = {entityId: poleId, userId: userId, assetStatus: assetStatus,isFromRejection:isFromRejection};
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}assignPole`, obj, {observe: 'response'});
  }

  download(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadFile`, obj, {headers: this.headers, observe: 'response'});
  }
  downloadRejectionReports(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadRejectionReportsFile`, obj, {headers: this.headers, observe: 'response'});
  }
  generateTemplate(utilityId: number): Observable<any> {
    return this.http.get(`${this.pathApi}generateTemplate?utilityId=${utilityId}`);
  }

  uploadFile(obj: any, utilityId: number): Observable<any> {
    return this.http.post(`${this.pathApi}uploadFile?utilityId=${utilityId}`, obj);
  }  

  getBuildingsStats() {
    return this.http.get<ResponsePackage<KeyValueItem[]>>(`${this.pathApi}getStats`,{headers: this.headers, observe: 'response'});
  }
  getPoleRejectionReportsStats() {
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}getRejectionReportStats`,{headers: this.headers, observe: 'response'});
  }
  getHistory(dtId?: any): Observable<any> {
    return this.http.get(`${this.pathApi}getHistory/`+dtId, {observe: 'response'});
}
getPoleDataQuality(id:number):Observable<any>{
  return this.http.get(`${this.pathApi}getPoleDataQuality/${id}`, {observe: 'response'});
}
rejectPole(obj : any): Observable<any> {
  return this.http.post(`${this.pathApi}rejectPole`, obj, {headers: this.headers, observe: 'response'});
}
approvePole(obj : any): Observable<any> {
  return this.http.post(`${this.pathApi}approvePole`, obj, {headers: this.headers, observe: 'response'});
  }
  auditPole(id: number): Observable<any> {
    return this.http.post(`${this.pathApi}auditPole/${id}`, id, { headers: this.headers, observe: 'response' });
}
}
