<div class="login-page">
    <div class="page-wrap">
      <div class="logo">
        <img src="../../../assets/logo-md.svg" alt="CAIMs Logo">
      </div>
      <div class="form-wrapper">
        <h1>Need help?</h1>
        <p class="welcome-text">Send us a detail of what you need help with.</p>
        <form ngNativeValidate (submit)="sendMail()" *ngIf="showEmailForm">
          <div class="form-item form-floating">
            <input [(ngModel)]="fullName" name="fullName" type="text" class="form-control" id="floatingInput" placeholder=" " required>
            <label for="floatingInput">Full name</label>
          </div>
          <div style="display: flex; flex-direction: row;  justify-content: space-between;">
            <div class="form-item form-floating" style="width: 49%;">
                <input [(ngModel)]="email" name="email" type="text" class="form-control" id="floatingInput3" placeholder=" " pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" required>
                <label for="floatingInput3">Email address</label>
            </div>
            <div class="form-item form-floating" style="width: 49%;">
              <input
              id="floatingInput"
              type="tel"
              class="form-control"
              (blur)="onBlur()"
              (keypress)="onInputKeyPress($event)"
              [(ngModel)]="phone"
              #telInput
              name="tellInput"
              required
            />  
            </div>
          </div>
          <div class="form-item form-floating mb-3">
              <input [(ngModel)]="organisation" name="organisation" type="text" class="form-control" id="floatingInput4" placeholder=" " required>
              <label for="floatingInput4">Organisation</label>
          </div>
          <div class="form-item form-floating mb-3">
              <input [(ngModel)]="subject" name="subject" type="text" class="form-control" id="floatingInput5" placeholder=" " required>
              <label for="floatingInput5">Subject</label>
          </div>
          <div class="form-item form-floating mb-3">
            <textarea rows="3" [(ngModel)]="message" name="message" class="form-control" id="floatingInput6" placeholder=" " required></textarea>
            <label for="floatingInput6">Message</label>
          </div>
          <button type="submit" class="btn btn-primary">Send message</button>
        </form>
        <div class="help"><a class="link" [routerLink]="''">Back to Log in screen</a></div>
      </div>
    </div>
  
    
  </div>
  
  
  <div class="message-sent-wrapper" *ngIf="messageSent" [routerLink]="''">
    <div class="message-sent">
      <div class="close-btn" [routerLink]="''"><div></div></div>
      <img src="../../../assets/animations/envelop-animation.gif" alt="Envelop animation">
      <h1>Message sent!</h1>
      <p>You massage has been sent. We will get back to you shortly.</p>
      <div class="help"><a class="link" [routerLink]="''">Back to Log in screen</a></div>
    </div>
  </div>