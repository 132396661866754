<div *ngIf="!isMobile" [ngClass]="{'disable-div': !permissionsService.isRoleManagementCreateEditAllowed}">
    <!-- Customers -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigBuildings">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox" [checked]="isChecked([PermissionsEnum.Customer_View,PermissionsEnum.Customer_CreateEdit,PermissionsEnum.Customer_Delete,PermissionsEnum.Customer_AuditReview,PermissionsEnum.Customer_ApproveReject,PermissionsEnum.Customer_Assign,PermissionsEnum.Customer_RecallAssigned
                        , PermissionsEnum.Customer_RecallValidated, PermissionsEnum.Customer_Retag])" (change)="onChangePermission([PermissionsEnum.Customer_View,PermissionsEnum.Customer_CreateEdit,PermissionsEnum.Customer_Delete,PermissionsEnum.Customer_AuditReview,PermissionsEnum.Customer_ApproveReject,PermissionsEnum.Customer_Assign,PermissionsEnum.Customer_RecallAssigned
                        , PermissionsEnum.Customer_RecallValidated, PermissionsEnum.Customer_Retag], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Customers</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Customer_View])"
                    (change)="onChangePermission([PermissionsEnum.Customer_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Customer_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Customer_CreateEdit], $event);" /> Create/Edit</label>
             <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Customer_Delete])"
                    (change)="onChangePermission([PermissionsEnum.Customer_Delete], $event);" /> Delete</label>
            <label *ngIf="permissionsService.hasDashboardConfigBuildings"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Customer_Assign])"
                    (change)="onChangePermission([PermissionsEnum.Customer_Assign], $event);" /> Assign</label>
            <label *ngIf="permissionsService.hasDashboardConfigBuildings"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Customer_ApproveReject])"
                    (change)="onChangePermission([PermissionsEnum.Customer_ApproveReject], $event);" />
                Approve/Reject</label>
            <label *ngIf="permissionsService.hasDashboardConfigBuildings"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Customer_AuditReview])"
                    (change)="onChangePermission([PermissionsEnum.Customer_AuditReview], $event);" /> Audit
                Review</label>
            <label *ngIf="permissionsService.hasDashboardConfigBuildings"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Customer_RecallAssigned])"
                    (change)="onChangePermission([PermissionsEnum.Customer_RecallAssigned], $event);" /> Recall
                assigned</label>
            <label *ngIf="permissionsService.hasDashboardConfigBuildings"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Customer_RecallValidated])"
                    (change)="onChangePermission([PermissionsEnum.Customer_RecallValidated], $event);" /> Recall
                validated</label>
            <label *ngIf="permissionsService.hasDashboardConfigBuildings"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Customer_Retag])"
                    (change)="onChangePermission([PermissionsEnum.Customer_Retag], $event);" /> Retag</label>
            <label *ngIf="isRegularizationUploadVisible"><input type="checkbox" [checked]="isChecked([PermissionsEnum.Customer_Regularization])"
                    (change)="onChangePermission([PermissionsEnum.Customer_Regularization], $event);" />
                Regularization</label>
        </div>

    </div>
    <div class="component" *ngIf="permissionsService.hasDashboardConfigBuildings">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Customer_ViewCrud, PermissionsEnum.Customer_CreateEditCrud, PermissionsEnum.Customer_AssignForDecommission])"
                        (change)="onChangePermission([PermissionsEnum.Customer_ViewCrud, PermissionsEnum.Customer_CreateEditCrud, PermissionsEnum.Customer_AssignForDecommission], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Customers CRUD</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Customer_ViewCrud])"
                    (change)="onChangePermission([PermissionsEnum.Customer_ViewCrud], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Customer_CreateEditCrud])"
                    (change)="onChangePermission([PermissionsEnum.Customer_CreateEditCrud], $event);" /> Create/Edit
            </label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Customer_AssignForDecommission])"
                    (change)="onChangePermission([PermissionsEnum.Customer_AssignForDecommission], $event);" /> Assign
                for decommissioning
            </label>
        </div>
    </div>
    <!-- Transformers -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigDTS">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox" [checked]="isChecked([PermissionsEnum.Transformers_View,PermissionsEnum.Transformers_CreateEdit,PermissionsEnum.Transformers_CreateEditCrud,
                        PermissionsEnum.Transformers_ViewCrud,PermissionsEnum.Transformers_Delete,PermissionsEnum.Transformers_Assign,
                        PermissionsEnum.Transformers_ApproveReject,PermissionsEnum.Transformers_AuditReview,PermissionsEnum.Transformers_RecallAssigned,
                         PermissionsEnum.Transformers_RecallValidated, PermissionsEnum.Transformers_Retag])"
                        (change)="onChangePermission([PermissionsEnum.Transformers_View,PermissionsEnum.Transformers_ViewCrud,PermissionsEnum.Transformers_CreateEditCrud,
                        PermissionsEnum.Transformers_CreateEdit,PermissionsEnum.Transformers_Delete,PermissionsEnum.Transformers_Assign,
                        PermissionsEnum.Transformers_ApproveReject,PermissionsEnum.Transformers_AuditReview,
                        PermissionsEnum.Transformers_RecallAssigned, PermissionsEnum.Transformers_RecallValidated, PermissionsEnum.Transformers_Retag], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Transformers</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Transformers_View])"
                    (change)="onChangePermission([PermissionsEnum.Transformers_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Transformers_ViewCrud])"
                    (change)="onChangePermission([PermissionsEnum.Transformers_ViewCrud], $event);" /> View CRUD</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Transformers_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Transformers_CreateEdit], $event);" />
                Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Transformers_CreateEditCrud])"
                    (change)="onChangePermission([PermissionsEnum.Transformers_CreateEditCrud], $event);" />
                Create/Edit CRUD</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Transformers_Delete])"
                    (change)="onChangePermission([PermissionsEnum.Transformers_Delete], $event);" /> Delete</label>
            <label *ngIf="permissionsService.hasDashboardConfigDTS"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Transformers_Assign])"
                    (change)="onChangePermission([PermissionsEnum.Transformers_Assign], $event);" /> Assign</label>
            <label *ngIf="permissionsService.hasDashboardConfigDTS"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Transformers_ApproveReject])"
                    (change)="onChangePermission([PermissionsEnum.Transformers_ApproveReject], $event);" />
                Approve/Reject</label>
            <label *ngIf="permissionsService.hasDashboardConfigDTS"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Transformers_AuditReview])"
                    (change)="onChangePermission([PermissionsEnum.Transformers_AuditReview], $event);" /> Audit
                Review</label>
            <label *ngIf="permissionsService.hasDashboardConfigDTS"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Transformers_RecallAssigned])"
                    (change)="onChangePermission([PermissionsEnum.Transformers_RecallAssigned], $event);" /> Recall
                assigned</label>
            <label *ngIf="permissionsService.hasDashboardConfigDTS"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Transformers_RecallValidated])"
                    (change)="onChangePermission([PermissionsEnum.Transformers_RecallValidated], $event);" /> Recall
                validated</label>
            <label *ngIf="permissionsService.hasDashboardConfigDTS"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Transformers_Retag])"
                    (change)="onChangePermission([PermissionsEnum.Transformers_Retag], $event);" /> Retag</label>
        </div>
    </div>
    <!-- Poles -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigPoles">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox" [checked]="isChecked([PermissionsEnum.Pole_View,PermissionsEnum.Pole_CreateEdit,PermissionsEnum.Pole_Delete,PermissionsEnum.Pole_Assign,
                        PermissionsEnum.Pole_ApproveReject,PermissionsEnum.Pole_AuditReview,PermissionsEnum.Pole_RecallAssigned,
                         PermissionsEnum.Pole_RecallValidated, PermissionsEnum.Pole_Retag])"
                        (change)="onChangePermission([PermissionsEnum.Pole_View,PermissionsEnum.Pole_CreateEdit,PermissionsEnum.Pole_Delete,PermissionsEnum.Pole_Assign,
                        PermissionsEnum.Pole_ApproveReject,PermissionsEnum.Pole_AuditReview,
                        PermissionsEnum.Pole_RecallAssigned, PermissionsEnum.Pole_RecallValidated, PermissionsEnum.Pole_Retag], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Poles</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Pole_View])"
                    (change)="onChangePermission([PermissionsEnum.Pole_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Pole_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Pole_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Pole_Delete])"
                    (change)="onChangePermission([PermissionsEnum.Pole_Delete], $event);" /> Delete</label>
            <label *ngIf="permissionsService.hasDashboardConfigPoles"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Pole_Assign])"
                    (change)="onChangePermission([PermissionsEnum.Pole_Assign], $event);" /> Assign</label>
            <label *ngIf="permissionsService.hasDashboardConfigPoles"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Pole_ApproveReject])"
                    (change)="onChangePermission([PermissionsEnum.Pole_ApproveReject], $event);" />
                Approve/Reject</label>
            <label *ngIf="permissionsService.hasDashboardConfigPoles"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Pole_AuditReview])"
                    (change)="onChangePermission([PermissionsEnum.Pole_AuditReview], $event);" /> Audit Review</label>
            <label *ngIf="permissionsService.hasDashboardConfigPoles"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Pole_RecallAssigned])"
                    (change)="onChangePermission([PermissionsEnum.Pole_RecallAssigned], $event);" /> Recall
                assigned</label>
            <label *ngIf="permissionsService.hasDashboardConfigPoles"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Pole_RecallValidated])"
                    (change)="onChangePermission([PermissionsEnum.Pole_RecallValidated], $event);" /> Recall
                validated</label>
            <label *ngIf="permissionsService.hasDashboardConfigPoles"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Pole_Retag])"
                    (change)="onChangePermission([PermissionsEnum.Pole_Retag], $event);" /> Retag</label>
        </div>
    </div>
    <!-- Buildings -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigBuildings">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox" [checked]="isChecked([PermissionsEnum.Building_View,PermissionsEnum.Building_CreateEdit,PermissionsEnum.Building_Delete,PermissionsEnum.Building_Assign,
                        PermissionsEnum.Building_ApproveReject,PermissionsEnum.Building_AuditReview,PermissionsEnum.Building_RecallAssigned,
                         PermissionsEnum.Building_RecallValidated, PermissionsEnum.Building_Retag])"
                        (change)="onChangePermission([PermissionsEnum.Building_View,PermissionsEnum.Building_CreateEdit,PermissionsEnum.Building_Delete,
                        PermissionsEnum.Building_Assign,PermissionsEnum.Building_ApproveReject,PermissionsEnum.Building_AuditReview,
                        PermissionsEnum.Building_RecallAssigned, PermissionsEnum.Building_RecallValidated, PermissionsEnum.Building_Retag], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Buildings</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Building_View])"
                    (change)="onChangePermission([PermissionsEnum.Building_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Building_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Building_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Building_Delete])"
                    (change)="onChangePermission([PermissionsEnum.Building_Delete], $event);" /> Delete</label>
            <label *ngIf="permissionsService.hasDashboardConfigBuildings"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Building_Assign])"
                    (change)="onChangePermission([PermissionsEnum.Building_Assign], $event);" /> Assign</label>
            <label *ngIf="permissionsService.hasDashboardConfigBuildings"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Building_ApproveReject])"
                    (change)="onChangePermission([PermissionsEnum.Building_ApproveReject], $event);" />
                Approve/Reject</label>
            <label *ngIf="permissionsService.hasDashboardConfigBuildings"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Building_AuditReview])"
                    (change)="onChangePermission([PermissionsEnum.Building_AuditReview], $event);" /> Audit
                Review</label>
            <label *ngIf="permissionsService.hasDashboardConfigBuildings"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Building_RecallAssigned])"
                    (change)="onChangePermission([PermissionsEnum.Building_RecallAssigned], $event);" /> Recall
                assigned</label>
            <label *ngIf="permissionsService.hasDashboardConfigBuildings"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Building_RecallValidated])"
                    (change)="onChangePermission([PermissionsEnum.Building_RecallValidated], $event);" /> Recall
                validated</label>
            <label *ngIf="permissionsService.hasDashboardConfigBuildings"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Building_Retag])"
                    (change)="onChangePermission([PermissionsEnum.Building_Retag], $event);" /> Retag</label>
        </div>
    </div>
    <!-- Meters -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigMeters">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Meters_View,PermissionsEnum.Meters_CreateEdit,
                        PermissionsEnum.Meters_Delete,PermissionsEnum.Meters_ApproveReject, PermissionsEnum.Meters_Retag])" (change)="onChangePermission([PermissionsEnum.Meters_View,PermissionsEnum.Meters_CreateEdit,PermissionsEnum.Meters_Delete,
                        PermissionsEnum.Meters_ApproveReject, PermissionsEnum.Meters_Retag], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Meters</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Meters_View])"
                    (change)="onChangePermission([PermissionsEnum.Meters_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Meters_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Meters_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Meters_Delete])"
                    (change)="onChangePermission([PermissionsEnum.Meters_Delete], $event);" /> Delete</label>
            <label *ngIf="permissionsService.hasDashboardConfigMeters"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Meters_ApproveReject])"
                    (change)="onChangePermission([PermissionsEnum.Meters_ApproveReject], $event);" />
                Approve/Reject</label>
            <label *ngIf="permissionsService.hasDashboardConfigMeters"><input type="checkbox"
                    [checked]="isChecked([PermissionsEnum.Meters_Retag])"
                    (change)="onChangePermission([PermissionsEnum.Meters_Retag], $event);" /> Retag</label>
        </div>
    </div>
    <!-- Survey -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigSurvey">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Survey_View,PermissionsEnum.Survey_CreateEdit,PermissionsEnum.Survey_Delete,PermissionsEnum.Survey_ApproveReject])"
                        (change)="onChangePermission([PermissionsEnum.Survey_View,PermissionsEnum.Survey_CreateEdit,PermissionsEnum.Survey_Delete,PermissionsEnum.Survey_ApproveReject], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Surveys</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Survey_View])"
                    (change)="onChangePermission([PermissionsEnum.Survey_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Survey_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Survey_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Survey_Delete])"
                    (change)="onChangePermission([PermissionsEnum.Survey_Delete], $event);" /> Delete</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Survey_ApproveReject])"
                    (change)="onChangePermission([PermissionsEnum.Survey_ApproveReject], $event);" />
                Approve/Reject</label>
        </div>
    </div>
    <!-- Installation -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigInstallation">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Installation_View,PermissionsEnum.Installation_CreateEdit,PermissionsEnum.Installation_Delete,PermissionsEnum.Installation_ApproveReject])"
                        (change)="onChangePermission([PermissionsEnum.Installation_View,PermissionsEnum.Installation_CreateEdit,PermissionsEnum.Installation_Delete,PermissionsEnum.Installation_ApproveReject], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Installations</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Installation_View])"
                    (change)="onChangePermission([PermissionsEnum.Installation_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Installation_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Installation_CreateEdit], $event);" />
                Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Installation_Delete])"
                    (change)="onChangePermission([PermissionsEnum.Installation_Delete], $event);" /> Delete</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Installation_ApproveReject ])"
                    (change)="onChangePermission([PermissionsEnum.Installation_ApproveReject ], $event);" />
                Approve/Reject</label>

        </div>
    </div>
    <!-- User Management -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.UserManagement_View,PermissionsEnum.UserManagement_CreateEdit,PermissionsEnum.UserManagement_Delete,PermissionsEnum.UserManagement_BulkAssign])"
                        (change)="onChangePermission([PermissionsEnum.UserManagement_View,PermissionsEnum.UserManagement_CreateEdit,PermissionsEnum.UserManagement_Delete, PermissionsEnum.UserManagement_BulkAssign], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">User Management</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.UserManagement_View])"
                    (change)="onChangePermission([PermissionsEnum.UserManagement_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.UserManagement_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.UserManagement_CreateEdit], $event);" />
                Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.UserManagement_Delete])"
                    (change)="onChangePermission([PermissionsEnum.UserManagement_Delete], $event);" /> Delete</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.UserManagement_BulkAssign])"
                    (change)="onChangePermission([PermissionsEnum.UserManagement_BulkAssign], $event);" /> Bulk
                assign</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Organization_Create])"
                    (change)="onChangePermission([PermissionsEnum.Organization_Create], $event);" /> Create
                organization</label>
        </div>
    </div>
    <!-- Role Management -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.RoleManagement_View,PermissionsEnum.RoleManagement_CreateEdit,PermissionsEnum.RoleManagement_Delete])"
                        (change)="onChangePermission([PermissionsEnum.RoleManagement_View,PermissionsEnum.RoleManagement_CreateEdit,PermissionsEnum.RoleManagement_Delete], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Role Manager</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.RoleManagement_View])"
                    (change)="onChangePermission([PermissionsEnum.RoleManagement_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.RoleManagement_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.RoleManagement_CreateEdit], $event);" />
                Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.RoleManagement_Delete])"
                    (change)="onChangePermission([PermissionsEnum.RoleManagement_Delete], $event);" /> Delete</label>
        </div>
    </div>
    <!-- Transmission station -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Ts_View,PermissionsEnum.Ts_CreateEdit,PermissionsEnum.Ts_Delete])"
                        (change)="onChangePermission([PermissionsEnum.Ts_View,PermissionsEnum.Ts_CreateEdit,PermissionsEnum.Ts_Delete], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Transmission station</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Ts_View])"
                    (change)="onChangePermission([PermissionsEnum.Ts_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Ts_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Ts_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Ts_Delete])"
                    (change)="onChangePermission([PermissionsEnum.Ts_Delete], $event);" /> Delete</label>
        </div>
    </div>
    <!-- Main Feeder -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigFeeders">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.F33_View,PermissionsEnum.F33_CreateEdit,PermissionsEnum.F33_Delete])"
                        (change)="onChangePermission([PermissionsEnum.F33_View,PermissionsEnum.F33_CreateEdit,PermissionsEnum.F33_Delete], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Main feeder</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.F33_View])"
                    (change)="onChangePermission([PermissionsEnum.F33_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.F33_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.F33_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.F33_Delete])"
                    (change)="onChangePermission([PermissionsEnum.F33_Delete], $event);" /> Delete</label>
        </div>
    </div>
    <!-- SS -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Ss_View,PermissionsEnum.Ss_CreateEdit,PermissionsEnum.Ss_Delete])"
                        (change)="onChangePermission([PermissionsEnum.Ss_View,PermissionsEnum.Ss_CreateEdit,PermissionsEnum.Ss_Delete], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Injection substation</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Ss_View])"
                    (change)="onChangePermission([PermissionsEnum.Ss_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Ss_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Ss_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Ss_Delete])"
                    (change)="onChangePermission([PermissionsEnum.Ss_Delete], $event);" /> Delete</label>
        </div>
    </div>
    <!-- F11 -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigFeeders">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.F11_View,PermissionsEnum.F11_CreateEdit,PermissionsEnum.F11_Delete])"
                        (change)="onChangePermission([PermissionsEnum.F11_View,PermissionsEnum.F11_CreateEdit,PermissionsEnum.F11_Delete], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Sub feeder</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.F11_View])"
                    (change)="onChangePermission([PermissionsEnum.F11_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.F11_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.F11_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.F11_Delete])"
                    (change)="onChangePermission([PermissionsEnum.F11_Delete], $event);" /> Delete</label>
        </div>
    </div>
    <!-- Area -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Area_View,PermissionsEnum.Area_CreateEdit,PermissionsEnum.Area_Delete])"
                        (change)="onChangePermission([PermissionsEnum.Area_View,PermissionsEnum.Area_CreateEdit,PermissionsEnum.Area_Delete], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">{{utName}}</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Area_View])"
                    (change)="onChangePermission([PermissionsEnum.Area_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Area_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Area_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Area_Delete])"
                    (change)="onChangePermission([PermissionsEnum.Area_Delete], $event);" /> Delete</label>
        </div>
    </div>
    <!-- Region -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Region_View,PermissionsEnum.Region_CreateEdit,PermissionsEnum.Region_Delete])"
                        (change)="onChangePermission([PermissionsEnum.Region_View,PermissionsEnum.Region_CreateEdit,PermissionsEnum.Region_Delete], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">{{buName}}</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Region_View])"
                    (change)="onChangePermission([PermissionsEnum.Region_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Region_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Region_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Region_Delete])"
                    (change)="onChangePermission([PermissionsEnum.Region_Delete], $event);" /> Delete</label>
        </div>
    </div>
    <!-- Tariff -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Tariff_View,PermissionsEnum.Tariff_CreateEdit,PermissionsEnum.Tariff_Delete])"
                        (change)="onChangePermission([PermissionsEnum.Tariff_View,PermissionsEnum.Tariff_CreateEdit,PermissionsEnum.Tariff_Delete], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Tariff</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Tariff_View])"
                    (change)="onChangePermission([PermissionsEnum.Tariff_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Tariff_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Tariff_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Tariff_Delete])"
                    (change)="onChangePermission([PermissionsEnum.Tariff_Delete], $event);" /> Delete</label>
        </div>
    </div>
    <!-- Meter type -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.MeterType_View,PermissionsEnum.MeterType_CreateEdit,PermissionsEnum.MeterType_Delete])"
                        (change)="onChangePermission([PermissionsEnum.MeterType_View,PermissionsEnum.MeterType_CreateEdit,PermissionsEnum.MeterType_Delete], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Meter type</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.MeterType_View])"
                    (change)="onChangePermission([PermissionsEnum.MeterType_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.MeterType_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.MeterType_CreateEdit], $event);" />
                Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.MeterType_Delete])"
                    (change)="onChangePermission([PermissionsEnum.MeterType_Delete], $event);" /> Delete</label>
        </div>
    </div>
    <!-- AssetRating -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.AssetRating_View,PermissionsEnum.AssetRating_CreateEdit,PermissionsEnum.AssetRating_Delete])"
                        (change)="onChangePermission([PermissionsEnum.AssetRating_View,PermissionsEnum.AssetRating_CreateEdit,PermissionsEnum.AssetRating_Delete], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Asset rating</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.AssetRating_View])"
                    (change)="onChangePermission([PermissionsEnum.AssetRating_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.AssetRating_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.AssetRating_CreateEdit], $event);" />
                Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.AssetRating_Delete])"
                    (change)="onChangePermission([PermissionsEnum.AssetRating_Delete], $event);" /> Delete</label>
        </div>
    </div>
    <!-- AssetStatus -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.AssetStatus_View,PermissionsEnum.AssetStatus_CreateEdit,PermissionsEnum.AssetStatus_Delete])"
                        (change)="onChangePermission([PermissionsEnum.AssetStatus_View,PermissionsEnum.AssetStatus_CreateEdit,PermissionsEnum.AssetStatus_Delete], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Asset Status</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.AssetStatus_View])"
                    (change)="onChangePermission([PermissionsEnum.AssetStatus_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.AssetStatus_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.AssetStatus_CreateEdit], $event);" />
                Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.AssetStatus_Delete])"
                    (change)="onChangePermission([PermissionsEnum.AssetStatus_Delete], $event);" /> Delete</label>
        </div>
    </div>
    <!-- Meter Price -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.MeterPrice_View,PermissionsEnum.MeterPrice_CreateEdit,PermissionsEnum.MeterPrice_Delete])"
                        (change)="onChangePermission([PermissionsEnum.MeterPrice_View,PermissionsEnum.MeterPrice_CreateEdit,PermissionsEnum.MeterPrice_Delete], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Meter Price</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.MeterPrice_View])"
                    (change)="onChangePermission([PermissionsEnum.MeterPrice_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.MeterPrice_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.MeterPrice_CreateEdit], $event);" />
                Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.MeterPrice_Delete])"
                    (change)="onChangePermission([PermissionsEnum.MeterPrice_Delete], $event);" /> Delete</label>
        </div>
    </div>
    <!-- Dt meter type -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.DtMeterType_View,PermissionsEnum.DtMeterType_CreateEdit,PermissionsEnum.DtMeterType_Delete])"
                        (change)="onChangePermission([PermissionsEnum.DtMeterType_View,PermissionsEnum.DtMeterType_CreateEdit,PermissionsEnum.DtMeterType_Delete], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">DT Meter Type</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.DtMeterType_View])"
                    (change)="onChangePermission([PermissionsEnum.DtMeterType_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.DtMeterType_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.DtMeterType_CreateEdit], $event);" />
                Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.DtMeterType_Delete])"
                    (change)="onChangePermission([PermissionsEnum.DtMeterType_Delete], $event);" /> Delete</label>
        </div>
    </div>
    <!-- Audit log -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.AuditLog_View,PermissionsEnum.AuditLog_Download])"
                        (change)="onChangePermission([PermissionsEnum.AuditLog_View,PermissionsEnum.AuditLog_Download], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Audit log</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.AuditLog_View])"
                    (change)="onChangePermission([PermissionsEnum.AuditLog_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.AuditLog_Download])"
                    (change)="onChangePermission([PermissionsEnum.AuditLog_Download], $event);" /> Download</label>
        </div>
    </div>

    <!-- Audit Status -->
    <div class="component" *ngIf="(permissionsService.hasDashboardConfigBuildings || 
                                     permissionsService.hasDashboardConfigBuildings||
                                    permissionsService.hasDashboardConfigDTS || 
                                    permissionsService.hasDashboardConfigPoles) && !isDisabled">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox" [checked]="isChecked([PermissionsEnum.AuditStatus])"
                        (change)="onChangePermission([PermissionsEnum.AuditStatus], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Audit Status</label>
            </div>
        </div>
    </div>

    <!-- Validation Status -->
    <div class="component" *ngIf="(permissionsService.hasDashboardConfigBuildings || 
                                    permissionsService.hasDashboardConfigBuildings||
                                    permissionsService.hasDashboardConfigDTS || 
                                    permissionsService.hasDashboardConfigPoles) && !isDisabled">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox" [checked]="isChecked([PermissionsEnum.ValidationStatus])"
                        (change)="onChangePermission([PermissionsEnum.ValidationStatus], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Validation Status</label>
            </div>
        </div>
    </div>
    <!-- Work Orders -->
    <div class="component" *ngIf="!isDisabled && permissionsService.hasDashboardConfigWorkOrder">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.SiteVerification || PermissionsEnum.Maintenance ])"
                        (change)="onChangePermission([PermissionsEnum.SiteVerification|| PermissionsEnum.Maintenance], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Work Order</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.SiteVerification])"
                    (change)="onChangePermission([PermissionsEnum.SiteVerification], $event);" /> Site
                Verification</label>

            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Maintenance])"
                    (change)="onChangePermission([PermissionsEnum.Maintenance], $event);" /> Maintenance</label>
        </div>
    </div>
    <!-- Slt Status -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Slt_View, PermissionsEnum.Slt_CreateEdit, PermissionsEnum.Slt_Delete])"
                        (change)="onChangePermission([PermissionsEnum.Slt_View, PermissionsEnum.Slt_CreateEdit, PermissionsEnum.Slt_Delete], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">{{sltStatusName}}</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Slt_View])"
                    (change)="onChangePermission([PermissionsEnum.Slt_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Slt_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Slt_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Slt_Delete])"
                    (change)="onChangePermission([PermissionsEnum.Slt_Delete], $event);" /> Delete</label>
        </div>
    </div>

    <!-- Meter Stock Inventory -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.MeterStockInventory_View,PermissionsEnum.MeterStockInventory_Create])"
                        (change)="onChangePermission([PermissionsEnum.MeterStockInventory_View,PermissionsEnum.MeterStockInventory_Create], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Meter Stock Inventory</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.MeterStockInventory_View])"
                    (change)="onChangePermission([PermissionsEnum.MeterStockInventory_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.MeterStockInventory_Create])"
                    (change)="onChangePermission([PermissionsEnum.MeterStockInventory_Create], $event);" /> Create</label>
        </div>
    </div>

    <!-- Meter Stock Utilization -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.MeterStockUtilization_View])"
                        (change)="onChangePermission([PermissionsEnum.MeterStockUtilization_View], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Meter Stock Utilization</label>
            </div>
        </div>
    </div>

    <!-- Decommissioned Meter Stock -->
    <div class="component">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.DecommissionedMeterStock_View, PermissionsEnum.DecommissionedMeterStock_Approve])"
                        (change)="onChangePermission([PermissionsEnum.DecommissionedMeterStock_View,PermissionsEnum.DecommissionedMeterStock_Approve], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Decommissioned Meter Stock</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.DecommissionedMeterStock_View])"
                    (change)="onChangePermission([PermissionsEnum.DecommissionedMeterStock_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.DecommissionedMeterStock_Approve])"
                    (change)="onChangePermission([PermissionsEnum.DecommissionedMeterStock_Approve], $event);" /> Approve</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.DecommissionedMeterStock_Edit])"
                (change)="onChangePermission([PermissionsEnum.DecommissionedMeterStock_Edit], $event);" /> Edit</label>
        </div>
    </div>
</div>

<div *ngIf="isMobile" [ngClass]="{'disable-div': !permissionsService.isRoleManagementCreateEditAllowed}">
    <!-- Customers -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigBuildings && !isDisabled">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Customer_View,PermissionsEnum.Customer_CreateEdit, PermissionsEnum.Customer_Retag])"
                        (change)="onChangePermission([PermissionsEnum.Customer_View,PermissionsEnum.Customer_CreateEdit, PermissionsEnum.Customer_Retag], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Customers</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Customer_View])"
                    (change)="onChangePermission([PermissionsEnum.Customer_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Customer_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Customer_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Customer_Retag])"
                    (change)="onChangePermission([PermissionsEnum.Customer_Retag], $event);" /> Retag</label>
            <label *ngIf="isRegularizationUploadVisible"><input type="checkbox" [checked]="isChecked([PermissionsEnum.Customer_Regularization])"
                        (change)="onChangePermission([PermissionsEnum.Customer_Regularization], $event);" />
                    Regularization</label>
        </div>
    </div>
    <!-- DT -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigDTS && !isDisabled">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Transformers_View,PermissionsEnum.Transformers_CreateEdit, PermissionsEnum.Transformers_Retag])"
                        (change)="onChangePermission([PermissionsEnum.Transformers_View,PermissionsEnum.Transformers_CreateEdit, PermissionsEnum.Transformers_Retag], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Transformers</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Transformers_View])"
                    (change)="onChangePermission([PermissionsEnum.Transformers_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Transformers_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Transformers_CreateEdit], $event);" />
                Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Transformers_Retag])"
                    (change)="onChangePermission([PermissionsEnum.Transformers_Retag], $event);" /> Retag</label>
        </div>
    </div>
    <!-- Poles -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigPoles && !isDisabled">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Pole_View,PermissionsEnum.Pole_CreateEdit, PermissionsEnum.Pole_Retag])"
                        (change)="onChangePermission([PermissionsEnum.Pole_View,PermissionsEnum.Pole_CreateEdit, PermissionsEnum.Pole_Retag], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Poles</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Pole_View])"
                    (change)="onChangePermission([PermissionsEnum.Pole_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Pole_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Pole_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Pole_Retag])"
                    (change)="onChangePermission([PermissionsEnum.Pole_Retag], $event);" /> Retag</label>
        </div>
    </div>
    <!-- Buildings -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigBuildings && !isDisabled">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Building_View,PermissionsEnum.Building_CreateEdit, PermissionsEnum.Building_Retag])"
                        (change)="onChangePermission([PermissionsEnum.Building_View,PermissionsEnum.Building_CreateEdit, PermissionsEnum.Building_Retag], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Buildings</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Building_View])"
                    (change)="onChangePermission([PermissionsEnum.Building_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Building_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Building_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Building_Retag])"
                    (change)="onChangePermission([PermissionsEnum.Building_Retag], $event);" /> Retag</label>
        </div>
    </div>
    <!-- Meters -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigMeters && !isDisabled">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Meters_View,PermissionsEnum.Meters_CreateEdit, PermissionsEnum.Meters_Retag])"
                        (change)="onChangePermission([PermissionsEnum.Meters_View,PermissionsEnum.Meters_CreateEdit, PermissionsEnum.Meters_Retag], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Meters</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Meters_View])"
                    (change)="onChangePermission([PermissionsEnum.Meters_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Meters_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Meters_CreateEdit], $event);" /> Create/Edit</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Meters_Retag])"
                    (change)="onChangePermission([PermissionsEnum.Meters_Retag], $event);" /> Retag</label>
        </div>
    </div>
    <!-- Survey -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigSurvey && !isDisabled">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Survey_View,PermissionsEnum.Survey_CreateEdit])"
                        (change)="onChangePermission([PermissionsEnum.Survey_View,PermissionsEnum.Survey_CreateEdit], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Surveys</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Survey_View])"
                    (change)="onChangePermission([PermissionsEnum.Survey_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Survey_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Survey_CreateEdit], $event);" /> Create/Edit</label>
        </div>
    </div>

    <!-- Installation -->
    <div class="component" *ngIf="permissionsService.hasDashboardConfigInstallation && !isDisabled">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.Installation_View,PermissionsEnum.Installation_CreateEdit])"
                        (change)="onChangePermission([PermissionsEnum.Installation_View,PermissionsEnum.Installation_CreateEdit], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Installations</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Installation_View])"
                    (change)="onChangePermission([PermissionsEnum.Installation_View], $event);" /> View</label>
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Installation_CreateEdit])"
                    (change)="onChangePermission([PermissionsEnum.Installation_CreateEdit], $event);" />
                Create/Edit</label>

        </div>
    </div>
    <!-- Work Orders -->
    <div class="component" *ngIf="!isDisabled && permissionsService.hasDashboardConfigWorkOrder">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox"
                        [checked]="isChecked([PermissionsEnum.SiteVerification ||PermissionsEnum.Maintenance])"
                        (change)="onChangePermission([PermissionsEnum.SiteVerification||PermissionsEnum.Maintenance], $event);">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Work Order</label>
            </div>
        </div>
        <div class="permissionsComponent">
            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.SiteVerification])"
                    (change)="onChangePermission([PermissionsEnum.SiteVerification], $event);" /> Site
                Verification</label>

            <label><input type="checkbox" [checked]="isChecked([PermissionsEnum.Maintenance])"
                    (change)="onChangePermission([PermissionsEnum.Maintenance], $event);" /> Maintenance</label>
        </div>
    </div>

    <!-- Assignment -->
    <div class="component" *ngIf="isChecked([PermissionsEnum.Assignment])">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox" [checked]="isChecked([PermissionsEnum.Assignment])" disabled>
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Disconnection</label>
            </div>
        </div>
    </div>

    <!-- AdoraWorkOrder -->
    <div class="component" *ngIf="isChecked([PermissionsEnum.AdoraWorkOrder])">
        <div class="headerComponent">
            <div>
                <label class="switch">
                    <input type="checkbox" [checked]="isChecked([PermissionsEnum.AdoraWorkOrder])" disabled>
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <label class="title">Adora Work Order</label>
            </div>
        </div>
    </div>
    
</div>