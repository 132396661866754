export const environment = {
  production: true,
  pathApi: 'https://caims.azurewebsites.net/api/',
  name:'production',
  firebaseConfig: {
    apiKey: "AIzaSyASVbpuf6Im-ytvQpSlZANQkdtAwn0fCAE",
    authDomain: "caims-web.firebaseapp.com",
    projectId: "caims-web",
    storageBucket: "caims-web.appspot.com",
    messagingSenderId: "682219027084",
    appId: "1:682219027084:web:56f864064d7b41658ed9a9",
    measurementId: "G-ZZY3KQBV4R"
  }
};
