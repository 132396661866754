import { Component, OnInit, OnDestroy, ComponentFactoryResolver } from '@angular/core';
import { UserService } from '../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { BPSAdminLevel, User } from '../models/user.model';
import { RegionService } from '../services/region.service';
import { AreaService } from '../services/area.service';
import { PermissionsEnum, Role, SystemComponents } from '../models/role.model';
import { RoleService } from '../services/role.service';
import { TranslationService } from 'src/app/services/translation.service';
import { AuthService } from '../services/auth.service';
import { PermissionsService } from '../services/permissions.service';
import { UserMgmTabs } from '../enums/user-mgm-tabs';
import { OrganizationTeamApiService } from '../services/organization-team-api.service';
import { error } from 'console';
import { Organization } from '../models/organization.model';
import { OrganizationStatus } from '../models/customer.model';


interface OptionObj {
  key: number;
  value: string;
}

export interface Task {
  name: string;
  completed: boolean;
  subtasks?: Task[];
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit, OnDestroy {
  public PermissionsEnum = PermissionsEnum; 
  public currentPage: number = 1;
  public pageSize: number = 11;
//   public count: number = 0;
  public searchText: string = "";
  public sysComponents = SystemComponents;
  public users: User[] | any = [];

  //User info
  public addNewUser: boolean = false;
  public showPw: boolean = false;

  public userId: number = 0;
  public name: string = '';
  public lastName: string = '';
  public email: string = '';
  public organizationId: number | null = null;
  public role: Role = new Role();
  public pass: string = '';
  public regionIds: number[] = [];
  public level: BPSAdminLevel;
  
  areaIds: number[] = [];

  regionOptions: OptionObj[] = [];
  areaOptions: OptionObj[] = [];
  roleOptions: Role[] = [];
  levelOptions = [
    { name: 'Level 1 (Full Control)', value: BPSAdminLevel.Level1, stringValue: 'Level1' },
    { name: 'Level 2 (Read Only)', value: BPSAdminLevel.Level2, stringValue: 'Level2' }
  ];

  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');

  organizationForSelect: any;

  filterByOptions = OrganizationStatus;
  showFilterByStatus: boolean = false;
  showFilterByOrganization: boolean = false;
  
  mapStatus: Record<number, string> = {
    [OrganizationStatus.All]: 'All',
    [OrganizationStatus.Active]: 'Active',
    [OrganizationStatus.Inactive]: 'Inactive'
  };

  filterByValue: OrganizationStatus = OrganizationStatus.All;
  filterByOrganizationId: any = -1;

  filterByStatusName: string = this.mapStatus[OrganizationStatus.All];
  filterByOrganizationName: string;



  task: Task = {
    name: 'Indeterminate',
    completed: false,
    subtasks: [
      { name: 'Primary', completed: false },
      { name: 'Accent', completed: false },
      { name: 'Warn', completed: false },
    ],
  };
  allComplete: boolean = false;
  isBpsAdmin: boolean = false;
  showUpload: boolean = false;
  utilityId: number = 0;
  bpsAdminLevel: BPSAdminLevel;

  currentTab: number = UserMgmTabs.UserManagementTab;
  reset: boolean = false;

  countUserMgm = 0;
  countOrgTeam = 0;
  tabs = UserMgmTabs;


  // TODO: ORG/TEAM Change model from user to Organization team
  public organizationTeams: Organization[] | any = [];
  public newOrgTeamName: string = '';

  constructor(
    private userService: UserService,
    private organizationTeamService: OrganizationTeamApiService,
    private toastr: ToastrService,
    private regionService: RegionService,
    private areaService: AreaService,
      private translationService: TranslationService, public permissionsService: PermissionsService, private roleService: RoleService
  ) {
  }
  
  ngOnInit() {
    this.getOrganizationForSelect();
    this.filterByOrganizationType(-1); //Calling reloadTable and setting organization 
    if(!this.permissionsService.hasUtility) {
      this.isBpsAdmin = true;
      this.bpsAdminLevel = parseInt(localStorage.getItem('bpsAdminLevel') || "0");
    }
    else {
      this.getRegionsForOptions();
      this.getRoles();
    }
    this.utilityId = parseInt(localStorage.getItem('utilityId') || "");
    if (!this.isBpsAdmin)
      this.getAllTabsCount();
  }

  filterByStatusType(option: OrganizationStatus) {
    this.filterByValue = option;
    this.filterByStatusName = this.mapStatus[option];
    this.currentPage = 1;
    this.reloadTable();
  }

  filterByOrganizationType(organization: any) {
    if (organization == -1){
      this.filterByOrganizationName = "All"
      this.filterByOrganizationId = organization;
    }else{
      this.filterByOrganizationId = organization.id;
      this.filterByOrganizationName = organization.name
    }
    this.reloadTable();
  }

  changeTab(page: number) {
    this.getAllTabsCount();
    this.filterByValue = OrganizationStatus.All;
    this.filterByStatusName = this.mapStatus[OrganizationStatus.All];
    this.currentPage = 1;
    this.currentTab = page;
    this.searchText = '';
    // this.resetFilter(1);
    this.reloadTable();
  }

  getOrganizationForSelect(){
    this.organizationTeamService.getAllForSelect().subscribe(response => {
      this.organizationForSelect = response.data
      }, error => {
      this.toastr.error('An error occurred.');
    });
  }
  
  getRoles() {
    this.roleService.getAll(true).subscribe(response => {
      this.roleOptions = response.body?.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  updateAllComplete() {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => (t.completed = completed));
  }

  ngOnDestroy() {
  }

  selectAddOrganization(e: any) {
    if (e.target.value == "Select Organization")
      this.organizationId = null;
    else
      this.organizationId = parseInt(e.target.value);      
  }

  selectOption(e: any) {
    //reset options
    this.areaIds = [];
    this.regionIds = [];
    this.areaOptions = [];
    let tempRole = this.roleOptions.find(option => option.id == e.target.value);
    if(tempRole != null)
      this.role = tempRole;
  }

  selectLevelOption(e: any) {
    //reset options
    this.areaIds = [];
    this.regionIds = [];
    this.areaOptions = [];
    let tempRole = this.levelOptions.find(option => option.value == e.target.value);
    if(tempRole != null)
      this.level = tempRole.value;
  }

  selectRegion(e: any) {
    if(e.target.value != null){ //true -> dropdown, false -> multi select dropdown
      this.regionIds.push(+e.target.value);
      this.areaIds = [];
    }
    
    this.areaService.getAreasForOptionsByRegionIds(this.regionIds).subscribe(response => {
      this.areaOptions = response.body.data;
      this.areaIds = this.areaOptions.filter(item => this.areaIds.includes(item.key)).map(item => item.key);;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  handleClick(id: number) {
    let ids = this.areaIds.filter(x => x == id);
    if (ids.length == 0)
      this.areaIds.push(id);
    else
      this.areaIds.splice(this.areaIds.indexOf(id), 1);
  }

  handleCheck(id: number) {
    let ids = this.areaIds.filter(x => x == id);
    if (ids.length == 0)
      return false;
    else
      return true;

  }

  getRegionsForOptions() {
    this.regionService.getRegionsForOptions().subscribe(response => {
      this.regionOptions = response.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  // User
  onSearch() {
    this.currentPage = 1;
    this.reloadTable(this.searchText);
  }

  onEdit(user: any, isDisabled: boolean) {
    if(this.isBpsAdmin && this.bpsAdminLevel == BPSAdminLevel.Level2) {
      return;
    }
    console.log(user, "User")
    if (!isDisabled){
      this.getById(user.id);
      this.userId = user.id;
      this.name = user.firstName;
      this.name = user.firstName;
      this.lastName = user.lastName;
      this.organizationId = user.organizationId;
      this.email = user.email;
      this.addNewUser = true;
      let tempRole = this.roleOptions.find(option => option.id == user.role.id);
      if(tempRole != null)
        this.role = tempRole;

      let tempLevel = this.levelOptions.find(option => option.stringValue == user.level);
      if(tempLevel != null)
        this.level = tempLevel.value;
    }else
      this.toastr.info("You can't edit user with this role")
  }

  getById(id: number) {
    this.userService.getById(id).subscribe(
      data => {
        this.areaIds = data.data.areaIds;
        this.regionIds = data.data.regionIds ?? [];

        this.areaService.getAreasForOptionsByRegionIds(this.regionIds).subscribe(response => {
          this.areaOptions = response.body.data;
        }, error => {
          this.toastr.error('An error occurred.');
        });
      },
      error => {
        this.toastr.error('An error ocurred.');
      }
    );
  }

  onSetStatus(id: number, status: string, isDisabled: boolean) {
    if(this.isBpsAdmin && this.bpsAdminLevel == BPSAdminLevel.Level2) {
      return;
    }
    if(status == null) {
      this.toastr.warning('User does not have valid status. Please contact support.')
      return;
    }
    if (!isDisabled){
      let newStatus = '';
      if (status.toLowerCase() === "active") {
        newStatus = "Inactive"
      } else {
        newStatus = "Active"
      }
      let obj = {
        "id": id,
        "status": newStatus
      };
  
      this.userService.setStatus(obj).subscribe(x => {
        if (x.status === "200" || x.status === "OK") {
          this.toastr.success('Status changed successfully.');
          this.reloadTable();
        }
        else
          this.toastr.error(x.message);
      }, error => {
        this.toastr.error('An error occurred.');
      });
    }else
      this.toastr.info("You can't activate/deactivate user with this role")
  }

  selectOrganization(team: any){
    this.filterByOrganizationId = team.id
    this.filterByOrganizationName = team.name
    this.changeTab(UserMgmTabs.UserManagementTab);
  }

  reloadTable(searchText?: string, page: any = null) {
    if (page) {
      this.currentPage = page;
    }

    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        searchParam: searchText,
        status: this.filterByValue,
        organizationId: this.filterByOrganizationId
      }
    };

    if (this.currentTab === UserMgmTabs.UserManagementTab) {
        this.userService.getAll(dataIn).subscribe({
            next: (response) => {
              this.users = response.data.data;
              console.log(this.users);
              this.countUserMgm = response.data.count ?? 0;
              if (this.countUserMgm == 0) {
                this.toastr.info('No data for given input.');
              }
            },
            error: (_) => this.toastr.error('Error occured'),
          });
    
    } else {
        this.organizationTeamService.getAll(dataIn).subscribe({
            next: (response) => {
              this.organizationTeams = response.data.data;
              this.countOrgTeam = response.data.count ?? 0;
              if (this.countOrgTeam == 0) {
                this.toastr.info('No data for given input.');
              }
            },
            error: (_) => this.toastr.error('Error occured'),
        });
    }
  }

  onSave() {

  };

  onAddNewUser() {
    if(this.permissionsService.isUserManagementCreateEditAllowed)
      this.addNewUser = true;
    else
      this.toastr.error("You don't have permission")
  };

  onRoleAccess() {
    this.toastr.info('To be implemented');
  };

  onCancel() {
    this.addNewUser = false;
    this.initialState();
  };

  showPassword() {
    this.showPw = !this.showPw;
  };

  handleFormSubmit() {
    if (this.userId === 0) {
      if (this.pass?.length < 8) {
          this.toastr.error("Password must be at least 8 characters");
          return;
      }
      if (this.pass?.search(/[a-z]/i) < 0) {
          this.toastr.error("Password must contain at least one letter.");
          return;
      }
      if (this.pass?.search(/[0-9]/) < 0) {
          this.toastr.error("Password must contain at least one digit.");
          return;
      }
      if (this.pass?.search(/[$&+,:;=?@#|'<>.^*()%!-]/) < 0) {
          this.toastr.error("Password must contain at least one special character ($&+,:;=?@#|'<>.^*()%!-).");
          return;
      }
      if (this.pass.toLowerCase().includes(this.email.toLowerCase())) {
          this.toastr.error("Password must not contain user email.");
          return;
      }
    }
    if (this.email?.search(/[@]/) < 0) {
      this.toastr.error("Invalid Email format.");
      return;
    }
    if (this.email?.search(/[.]/) < 0) {
      this.toastr.error("Invalid Email format.");
      return;
    }

    if (!this.isBpsAdmin && this.areaIds.length == 0) {
      this.toastr.error("Please select at least one area.");
      return;
    }
    if(this.isBpsAdmin && this.level == null) {
      this.toastr.error("Please select BPS admin level!");
      return;
    }

    let obj = {
      "organizationId": this.organizationId,
      "id": this.userId,
      "firstName": this.name,
      "lastName": this.lastName,
      "email": this.email.replace(/\s/g, "").trim(),
      "password": this.pass,
      "role": this.isBpsAdmin ? null : this.role.id,
      "status": "Active",
      "areaIds": this.areaIds,
      "level": this.level
    };

    this.userService.saveUser(obj).subscribe(x => {
      if (x.status === "200" || x.status === "OK") {
        this.toastr.success('User saved successfully');
        this.addNewUser = false;
        this.reloadTable();
        this.initialState();
      }
      else
        this.toastr.error(x.message);

    }, error => {
      this.toastr.error('An error occurred.');
    });
  };

  //Checkboxes
  checkAllCheckBox(e: any) {
    if (this.currentTab === UserMgmTabs.UserManagementTab) {
        this.users.forEach((product: any) => product.checked = e.target.checked);
    } else {
        this.organizationTeams.forEach((product: any) => product.checked = e.target.checked);
    }
  }

  isAllCheckBoxChecked() {
    if (this.currentTab === UserMgmTabs.UserManagementTab) {
        return this.users.every((p: any) => p.checked);
    } else {
        return this.organizationTeams.every((p: any) => p.checked);
    }
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable(this.searchText);
  }


  initialState() {
    this.userId = 0;
    this.name = '';
    this.lastName = '';
    this.email = '';
    this.role = new Role();
    this.pass = '';
  }

  selectAllAreas(){
    this.areaIds = this.areaOptions.map(function(a){return a.key});
  }
  deSelectAllRegions(){
    this.regionIds=[];
  }

  selectAllRegions(){
    this.regionIds = this.regionOptions.map(function(a){return a.key});
  }
  deSelectAllAreas(){
    this.areaIds=[];
  }
  checkRegion(val: number) {
    if (this.regionIds.includes(val))
      this.regionIds = this.regionIds.filter(item => item != val);
    else
      this.regionIds.push(val)
  }
  
  isChecked(val: number): boolean {
    return this.regionIds?.includes(val);
  }

  // TODO: ORG/TEAM Add implementation
  onSetStatusOrganizationTeam(id: number, status: string) {
    var obj: any = { Id: id, Status: status }
    this.organizationTeamService.setStatus(obj).subscribe(response => {
      if (response.status == "OK"){
        this.toastr.success("Success");
        this.reloadTable();
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  getAllTabsCount() {
    this.organizationTeamService.getTabsCount().subscribe(response => {
      if (response.status == "OK"){
        this.countOrgTeam = response.data.organizationCount
        this.countUserMgm = response.data.userManagementCount
      }
      else
        this.toastr.error(response.message);
    }, error => {
      this.toastr.error('An error occurred.');
    });
  };

  saveNewOrgTeam() {
    var obj: any = { name: this.newOrgTeamName }
    this.organizationTeamService.saveOrganizationTeam(obj).subscribe(response => {
      if (response.status == "OK") {
        this.toastr.success("Success");
        this.reloadTable();
      }  else {
        this.toastr.error(response.message);
      }
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }
}