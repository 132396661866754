<div class="container-fluid customers-root">
  <div class="stats-root">
    <div class="stats-item row regularized-tab">
      <div class="col-11 regularized">
        <div class="regularized-title">Total Meter Stock</div>
        <div *ngIf="stats.total == undefined" class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div *ngIf="stats.total != undefined" class="count-number">
          {{ stats.total | number }}
        </div>
      </div>
      <div class="col-1">
        <div class="chevron" (click)="totalInfoOpen = !totalInfoOpen">
          <svg *ngIf="!totalInfoOpen" width="16" height="10" viewBox="0 0 16 10" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M2.34292 0.757812L0.928711 2.17202L7.9998 9.24307L15.0708 2.17205L13.6566 0.757842L7.9998 6.41467L2.34292 0.757812Z"
              fill="black" />
          </svg>
          <svg *ngIf="totalInfoOpen" width="16" height="10" viewBox="0 0 16 10" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M2.34292 9.24219L0.928711 7.82798L7.9998 0.756927L15.0708 7.82799L13.6566 9.2422L7.9998 3.58537L2.34292 9.24219Z"
              fill="black" />
          </svg>
        </div>
      </div>
      <div class="total-stats-root" *ngIf="totalInfoOpen">
        <div class="stats-item regularized-extra-info">
          <div>Single Phase</div>
          <div *ngIf="stats.singlePhase != undefined" class="count-number">
            <span class="revenue">{{ stats.singlePhase | number }}</span>
          </div>
        </div>
        <div class="stats-item regularized-extra-info">
          <div>Three Phase</div>
          <div *ngIf="stats.threePhase != undefined" class="count-number">
            <span class="revenue">{{ stats.threePhase | number }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="stats-item">
      <div>Total Installed</div>
      <div *ngIf="stats.total == undefined" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div *ngIf="stats.installed != undefined" class="count-number">
        {{ stats.installed | number }}
      </div>
    </div>
    <div class="stats-item">
      <div>Meter Stock Utilization %</div>
      <div *ngIf="stats.installed == undefined" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div *ngIf="stats.utilization != undefined" class="count-number">
        {{ stats.utilization | number }}
      </div>
    </div>
    <div class="stats-item">
      <div>Average age of Installed Meters</div>
      <div *ngIf="stats.averageAge == undefined" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div *ngIf="stats.averageAge != undefined" class="count-number">
        {{ stats.averageAge | number }}
      </div>
    </div>
  </div>

  <div class="table-root">
    <div class="table-action-wrapper d-flex align-items-center justify-content-between">
      <div class="d-flex searches">
        <div class="search-input">
          <img src="../../assets/icons/search.svg" alt="Search icon" (click)="search()" />
          <input type="text" placeholder="Search" [(ngModel)]="searchText" (keydown.enter)="search()"
            (ngModelChange)="this.searchTextUpdate.next($event)" />
        </div>
        <div class="search-type-picker">
          <div class="dropdown" (click)="showSelectSearchType = !showSelectSearchType" click-stop-propagation>
            <span>By:</span>
            <span>{{ selectedSearchTypeDisplay }}</span>
            <span class="arrow"><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down" /></span>
          </div>
          <div class="dropdown-opened" *ngIf="showSelectSearchType">
            <div (click)="selectSearchType(searchType)" *ngFor="let searchType of searchByOptions">
              <div>{{ searchType.value }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-auto d-flex">
        <div>
          <div class="d-flex">
            <div class="datetype-picker">
              <div class="dropdown date-type-dropdown" (click)="showSelectDateType = !showSelectDateType"
                click-stop-propagation>
                <span>Date type:</span>
                <span>{{ selectedDateTypeDisplay }}</span>
                <span class="arrow"><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down" /></span>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectDateType">
                <div (click)="
                      selectDateType(dateType);
                      datesValid() == true && (dateTo || dateFrom) && search()
                    " *ngFor="let dateType of dateTypes">
                  <div>{{ dateType.value }}</div>
                </div>
              </div>
            </div>
            <div class="datepicker-container">
              <label> From: </label>
              <input type="date" id="dateFrom" name="dateFrom" [ngClass]="
                    !datesValid()
                      ? 'btn datepicker-icon-error'
                      : 'btn datepicker-icon'
                  " [(ngModel)]="dateFrom" (change)="datesValid() == true && search()" />
            </div>

            <div class="datepicker-container">
              <label class="right-label"> To: </label>
              <input type="date" id="dateTo" name="dateTo" [ngClass]="
                    !datesValid()
                      ? 'btn datepicker-icon-error'
                      : 'btn datepicker-icon'
                  " [(ngModel)]="dateTo" (change)="datesValid() == true && search()" />
            </div>
          </div>
        </div>
        <button class="btn btn-icon" (click)="download()">
          <span>Download</span>
        </button>
      </div>
    </div>
    <div class="filter">
      <div class="filter-list">
        Filter
        <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="regions" [label]="buName + 's'"
          [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectRegion($event)"
          (applyFilterEmitter)="reloadTable(1)">
        </app-multi-select-dropdown>
        <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="areas" [label]="utName + 's'"
          [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectArea($event)"
          (applyFilterEmitter)="reloadTable(1)">
        </app-multi-select-dropdown>

        <select name="ct-select" (change)="selectCustomerType($event)" class="form-select">
          <option value="" [selected]="!customerType" disabled>
            Meter type
          </option>
          <option [selected]="customerType == o.value" *ngFor="let o of customerTypeOptions" value="{{ o.value }}">
            {{ o.name }}
          </option>
        </select>
        <select name="ct-select" (change)="selectMeterPhase($event)" class="form-select">
          <option value="" [selected]="!meterPhase" disabled>
            Meter phase
          </option>
          <option [selected]="meterPhase == o.value" *ngFor="let o of meterPhaseOptions" value="{{ o.value }}">
            {{ o.name }}
          </option>
        </select>
      </div>
      <div *ngIf="selectedFilters.length > 0">
        <app-filter-checklist [filters]="selectedFilters" (resetFilterEmitter)="resetFilter($event)"
          (removeFilterEmitter)="removeFilter($event)">
        </app-filter-checklist>
      </div>
    </div>
    <div class="table">
      <div *ngIf="meterStockItems">
        <!-- Add property later -->
        <table class="table border-bottom">
          <thead class="table-light">
            <tr>
              <th>
                <input type="checkbox" class="form-check-input" [ngModel]="allCheckboxesChecked"
                  (click)="checkAllCheckboxes()" />
              </th>
              <th>Meter Number</th>
              <th>Meter Phase</th>
              <th>Serial Number</th>
              <th>Meter Make</th>
              <th>Meter Type</th>
              <th>Meter Lifespan</th>
              <th>Meter Age</th>
              <th>Upload date</th>
              <th>Dispatch date</th>
              <th>{{ buName }}</th>
              <th>{{ utName }}</th>
              <th>Installation Date</th>
              <th>Installer Organization</th>
              <th>Account Number</th>
              <th>Seal Number</th>
              <th>Customer Name</th>
              <th>Customer Address</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let m of meterStockItems; let i = index">
              <td>
                <input type="checkbox" class="form-check-input" [ngModel]="m.isChecked"
                  (click)="checkMeterStockItem(m.id)" />
              </td>
              <td>
                {{ m.meterNumber | hasValue }}
              </td>
              <td>
                {{ m.meterPhase | hasValue }}
              </td>
              <td>
                {{ m.serialNumber | hasValue }}
              </td>
              <td>
                {{ m.meterMake | hasValue }}
              </td>
              <td>
                {{ m.meterType | hasValue }}
              </td>
              <td>
                {{ m.lifespan | hasValue }}
              </td>
              <td class="{{ getAgeClass(m.lifespan, m.age) }}">
                {{ m.age | hasValue }}
              </td>
              <td>
                {{ m.uploadDate ? (m.uploadDate | date : "dd/MM/yyyy") : "----" }}
              </td>
              <td>
                {{ m.dispatchDate ? (m.dispatchDate | date : "dd/MM/yyyy") : "----" }}
              </td>
              <td>
                {{ m.region | hasValue }}
              </td>
              <td>
                {{ m.area | hasValue }}
              </td>
              <td>
                {{ m.installationDate ? (m.installationDate | date : "dd/MM/yyyy") : "----" }}
              </td>
              <td>
                {{ m.organization | hasValue }}
              </td>
              <td>
                {{ m.accountNumber | hasValue }}
              </td>
              <td>
                {{ m.sealNumber | hasValue }}
              </td>
              <td>
                {{ m.customerName | hasValue }}
              </td>
              <td>
                {{ m.customerAddress | hasValue }}
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <app-paging *ngIf="count > 0" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage"
            (pageChange)="pageChange($event)"></app-paging>
        </div>
      </div>
    </div>
  </div>
</div>