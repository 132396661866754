<div class="container-fluid customers-root">
  <app-card-analytics [stats]="stats"
  (shareFilterValue)="onFilterBy($event)">
  </app-card-analytics>

  <div class="table-root">
    <div
      class="table-action-wrapper d-flex align-items-center justify-content-between"
    >
      <div class="search-input">
        <img
          src="../../assets/icons/search.svg"
          alt="Search icon"
          (click)="search()"
        />
        <input
          type="text"
          placeholder="Search"
          [(ngModel)]="searchText"
          (keydown.enter)="search()"
          (ngModelChange)="this.searchTextUpdate.next($event)"
        />
      </div>
      <div class="ml-auto d-flex">
        <div>
          <div class="d-flex">
            <div class="datetype-picker">
              <div
                class="dropdown"
                (click)="showSelectDateType = !showSelectDateType"
                click-stop-propagation
              >
                <span>Date type:</span>
                <span>{{ selectedDateTypeDisplay }}</span>
                <span class="arrow"
                  ><img
                    src="../../assets/icons/gray-arrow-down.svg"
                    alt="Gray arrow down"
                /></span>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectDateType">
                <div
                  (click)="
                    selectDateType(dateType); (datesValid() == true && (dateTo || dateFrom)) && search()
                  "
                  *ngFor="let dateType of dateTypes"
                >
                  <div>{{ dateType.value }}</div>
                </div>
              </div>
            </div>
            <div class="datepicker-container">
              <label for="dateFrom"> From: </label>
              <input
                type="date"
                id="dateFrom"
                name="dateFrom"
                [ngClass]="
                  !datesValid()
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateFrom"
                (change)="datesValid() == true && search()"
              />
            </div>

            <div class="datepicker-container">
              <label for="dateTo" class="right-label"> To: </label>
              <input
                type="date"
                id="dateTo"
                name="dateTo"
                [ngClass]="
                  !datesValid()
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateTo"
                (change)="datesValid() == true && search()"
              />
            </div>
          </div>
        </div>
        <div
          class="sort-by-root"
          (click)="showSortBy = !showSortBy"
          click-stop-propagation
        >
          <div class="dropdown">
            <span>Sort by:</span>
            <span>{{ sortByName }}</span>
            <span
              ><img
                src="../../assets/icons/gray-arrow-down.svg"
                alt="Gray arrow down"
            /></span>
          </div>
          <div class="dropdown-opened" *ngIf="showSortBy">
            <div (click)="sortBy(sortByOptions.ID)">ID</div>
            <div (click)="sortBy(sortByOptions.NEWEST)">Newest</div>
            <div (click)="sortBy(sortByOptions.SLRN_ASC)">SRLN A.</div>
            <div (click)="sortBy(sortByOptions.SLRN_DESC)">SLRN D.</div>
          </div>
        </div>

        <button class="btn btn-icon" (click)="download()">
          <span>Download</span>
        </button>
        <div
          *ngIf="
            permissionsService.isTransformersCreateEditAllowed
          "
        >
          <button
            class="btn btn-primary btn-upload"
            (click)="showUpload = !showUpload"
            click-stop-propagation
          >
            <img src="../../assets/icons/chevron-down.svg" alt="Chevron down" />
          </button>
          <div
            *ngIf="showUpload"
            class="xlsx-upload"
            [routerLink]="'/upload/4/' + utilityId"
          >
            <div><img src="../../assets/icons/upload-arrow.svg" alt="Upload icon" /></div>
            <div>Upload with XLSX</div>
          </div>
        </div>
        <button
          class="btn btn-primary btn-add-new"
          routerLink="add"
          *ngIf="
            permissionsService.isTransformersCreateEditAllowed
          "
        >
          <img src="../../assets/icons/plus.svg" alt="Plus icon" />
          <span>New transformer</span>
        </button>
      </div>
    </div>
    <div class="filter">
      <div class="filter-list">
        Filter
        <app-multi-select-dropdown
          class="multiselect"
          [clicked]="clicked"
          [items]="regions"
          [label]="buName + 's'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectRegion($event)"
          (applyFilterEmitter)="reloadTable(1)"
        >
        </app-multi-select-dropdown>
        <app-multi-select-dropdown
          class="multiselect"
          [clicked]="clicked"
          [items]="areas"
          [label]="utName + 's'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectArea($event)"
          (applyFilterEmitter)="reloadTable(1)"
        >
        </app-multi-select-dropdown>
        
        <select name="st-select" (change)="selectUserType($event)" class="form-select">
          <option value="" [selected]="!userType" disabled>
            User type
          </option>
          <option [selected]="userType == o.value" *ngFor="let o of userTypeOptions" value="{{ o.value }}">
            {{ o.name }}
          </option>
        </select>
        
        <app-multi-select-dropdown class="multiselect" [searchFilterActive]="true"
          (filterValue)="applySearchFilter($event)" [clicked]="clicked" [items]="filteredUsers" [label]="'Users'"
          [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectUsers($event)"
          (applyFilterEmitter)="reloadTable(1)">
        </app-multi-select-dropdown>

        <select name="ts" (change)="selectTS($event)" class="form-select">
          <option value="0" [selected]="selectedTs == 0" disabled>
            Transmision station
          </option>
          <option [selected]="selectedTs == o.id" *ngFor="let o of ts" value="{{ o.id }}">
            {{ o.name }}
          </option>
        </select>

        <select
          name="voltage"
          (change)="selectFeederVoltage($event)"
          class="form-select"
        >
          <option value="0" [selected]="feederVoltage == 0" disabled>
            Feeder Voltage
          </option>
          <option [selected]="feederVoltage == o.value" *ngFor="let o of feederVoltageOptions" value="{{ o.value }}">
            {{ o.name }}
          </option>
        </select>

        <select
          name="mount"
          (change)="selectMountPosition($event)"
          class="form-select"
        >
          <option value="0" [selected]="mountPosition == 0" disabled>
            Mount Position
          </option>
          <option [selected]="mountPosition == o.value" *ngFor="let o of mountPositionOptions" value="{{ o.value }}">
            {{ o.name }}
          </option>
        </select>
        <select name="type" (change)="selectDtType($event)" class="form-select">
          <option value="0" [selected]="dtType == 0" disabled>DT Type</option>
          <option [selected]="dtType == o.value" *ngFor="let o of dtTypeOptions" value="{{ o.value }}">
            {{ o.name }}
          </option>
        </select>
        <select
          name="status"
          (change)="selectDtStatus($event)"
          class="form-select"
        >
          <option value="0" [selected]="dtStatus == 0" disabled>
            DT Status
          </option>
          <option [selected]="dtStatus == o.value" *ngFor="let o of dtStatusOptions" value="{{ o.value }}">
            {{ o.name }}
          </option>
        </select>
        <select
          name="meter-status"
          (change)="selectMeterStatus($event)"
          class="form-select"
        >
          <option value="0" [selected]="meterStatus == 0" disabled>
            Meter Status
          </option>
          <option [selected]="meterStatus == o.value" *ngFor="let o of meterStatusOptions" value="{{ o.value }}">
            {{ o.name }}
          </option>
        </select>
        <select
          name="status"
          (change)="selectStatus($event)"
          class="form-select"
        >
          <option value="0" [selected]="status == 0" disabled>Validation Status</option>
          <option [selected]="status == o.value" *ngFor="let o of statusOptions" value="{{ o.value }}">
            {{ o.name }}
          </option>
        </select>
        <select
          name="kva-rating"
          (change)="selectKVARating($event)"
          class="form-select"
        >
          <option value="0" [selected]="!kvaRating" disabled>KVA Rating</option>
          <option [selected]="kvaRating == kva" *ngFor="let kva of kvaRatingOptions" value="{{ kva }}">
            {{ kva }}
          </option>
        </select>
      </div>

      <div *ngIf="selectedFilters.length > 0">
        <app-filter-checklist
          [filters]="selectedFilters"
          (resetFilterEmitter)="resetFilter($event)"
          (removeFilterEmitter)="removeFilter($event)"
        >
        </app-filter-checklist>
      </div>
    </div>
    <div class="table">
      <div *ngIf="dts">
        <table class="table border-bottom">
          <thead class="table-light">
            <tr>
              <th>
                <input
                  type="checkbox"
                  class="form-check-input"
                  [ngModel]="allCheckboxesChecked"
                  (click)="checkAllCheckboxes()"
                />
              </th>
              <th>Slrn</th>
              <th class="th-dt-name">Dt name</th>
              <th>Dt rating(KVA)</th>
              <th class="th-address">DT Address</th>
              <th>{{ utName }}</th>
              <th>{{ buName }}</th>
              <th>Feeder Name</th>
              <th>Feeder Voltage</th>
              <th>Connected Customers</th>
              <th>Type</th>
              <th>Validation Status</th>
              <th>Tag</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dt of dts; let i = index">
              <td>
                <input
                  type="checkbox"
                  class="form-check-input"
                  value="{{ dt.id }}"
                  [ngModel]="dt.isChecked"
                  (click)="checkDt(dt.id)"
                />
              </td>
              <td [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt?.slrn | hasValue }}
              </td>
              <td [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.name | hasValue }}
              </td>
              <td [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.kvaRating | hasValue }}
              </td>

              <td [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.locationAddress | hasValue }}
              </td>
              <td [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.area.name | hasValue }}
              </td>
              <td [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.area.regionName | hasValue }}
              </td>
              <td [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.feeder.name | hasValue }}
              </td>
              <td [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{
                  dt.feeder.feederType == "KV11" ? "11KV" : ("33KV" | hasValue)
                }}
              </td>
              <td [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.connectedCustomers | number | hasValue }}
              </td>

              <td [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.type | hasValue }}
              </td>

              <td [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                <div *ngIf="dt.dataQualityStatus"
                      class="building-status"
                      [ngClass]="{
                        'complete-building' : dt.dataQualityStatus == 'Approved',
                        'rejected-building' : dt.dataQualityStatus == 'Rejected'
                      }">
                    {{ dt.dataQualityStatus == 'Approved' ? 'Complete' : 'Rejected' }}
                </div>
                <div
                  *ngIf="dt.status && !dt.dataQualityStatus"
                  class="building-status"
                  [ngClass]="{
                    'pending-building': dt.status == 'Pending',
                    'assigned-building': dt.status == 'Assigned' || dt.status == 'Retagged'
                  }"
                >
                  {{ dt.status }}
                </div>
                <div *ngIf="!dt.status && !dt.dataQualityStatus" class="building-status">--------</div>
              </td>
              <td>
                <div
                  [routerLink]="['edit', dt.id]"
                  (click)="setFilterStorage()"
                  class="tag-customer"
                >
                  Existing
                </div>
              </td>
              <td
                class="more-icon"
                (click)="setFilterStorage()"
                [routerLink]="['edit', dt.id]"
              >
                <img
                  src="../../assets/icons/more-icon.svg"
                  alt="More icon"
                  class="more-icon"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <app-paging
            *ngIf="count > 0"
            [pageSize]="pageSize"
            [useDirect]="true"
            [count]="count"
            [currentPage]="currentPage"
            (pageChange)="pageChange($event)"
          ></app-paging>
        </div>
      </div>
    </div>
  </div>
</div>
