import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { Area } from 'src/app/models/area.model';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import { SortByOptions, UserType } from 'src/app/models/customer.model';
import { KeyValueItem } from 'src/app/models/keyValueItem.model';
import { PoleDetails } from 'src/app/models/pole.model';
import { Region } from 'src/app/models/region.model';
import { AreaService } from 'src/app/services/area.service';
import { PoleService } from 'src/app/services/pole.service';
import { RegionService } from 'src/app/services/region.service';
import { TranslationService } from 'src/app/services/translation.service';
import { PermissionsEnum, SystemComponents } from 'src/app/models/role.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionsService } from 'src/app/services/permissions.service';
import { OptionObj } from 'src/app/models/util.model';
import { UserForFilter } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-poles',
  templateUrl: './poles.component.html',
  styleUrls: ['./poles.component.scss'],
})
export class PolesComponent implements OnInit, OnDestroy {
  public PermissionsEnum = PermissionsEnum;
  stats: KeyValueItem[] = [];

  poles: PoleDetails[] = [];

  mapSortBy: Record<number, string> = {
    [SortByOptions.NEWEST]: 'Newest',
    [SortByOptions.SLRN_ASC]: 'SLRN A',
    [SortByOptions.SLRN_DESC]: 'SLRN D',
    [SortByOptions.ID_ASC]: 'ID A',
    [SortByOptions.ID_DESC]: 'ID D',
  };

  count: number = 2;
  pageSize: number = 10;
  currentPage: number = 1;
  dateFrom: string;
  dateTo: string;
  searchText: string = '';
  searchTextUpdate = new Subject<string>();
  filterByValue: string = '';

  sortByValue: SortByOptions = SortByOptions.NEWEST;
  sortByOptions = SortByOptions;
  sortByName: string = this.mapSortBy[SortByOptions.NEWEST];

  showUpload: boolean = false;
  showSortBy: boolean = false;
  clickObservable: Observable<Event> = fromEvent(document, 'click');

  historyItems: any[] = [];
  isViewHistory: boolean = true;

  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  utilityId: number = 0;

  private ngUnsubscribe = new Subject<void>();
  selectedFilters: ChecklistItem[] = [];
  clicked: boolean = false;
  regions: Region[] = [];
  areas: Area[] = [];
  allAreas: Area[] = [];
  poleTypesOptions: OptionObj[] = [];
  poleType: number = 0;
  poleCondition: number = 0;
  poleStatus: number = 0;
  selectedAreas: number[] = [];
  selectedRegions: number[] = [];
  poleConditionOptions = [
    { name: 'Good', value: 1 },
    { name: 'Bent Leaning', value: 2 },
    { name: 'Vandalized', value: 3 },
  ];
  poleStatusOptions = [
    { name: 'Pending', value: 1 },
    { name: 'Complete', value: 2 },
    // { name: 'Incomplete', value: 3 },
    { name: 'Assigned', value: 4 },
    { name: 'Rejected', value: 5 },
    { name: 'Retagged', value: 6 },
  ];
  allCheckboxesChecked: boolean = false;
  dateTypes = [
    { name: 'LastUpdateTime', value: 'Last Update Time' },
    { name: 'TaggedDate', value: 'Tagged Date' },
    { name: 'ValidatedDate', value: 'Validated Date' },
  ];
  selectedDateType: string;
  selectedDateTypeDisplay: string;
  showSelectDateType: boolean = false;
  slrnDropdownOpened: boolean = false;
  
  usersFilter: any; // Stores value of filter
  mobileUsers: UserForFilter[] = []; // Store only mobile users
  adminUsers: UserForFilter[] = []; // Store only admin users
  allUsers: UserForFilter[] = []; // Store mobile or admin users depending on filter
  filteredUsers: UserForFilter[] = []; // Used for display and for filtering users list
  selectedUsers: number[] = [];

  userType?: number;
  userTypeOptions = [
    { name: 'First Captured User', value: UserType.FirstCapturedUser },
    { name: 'Last Captured User', value: UserType.LastCapturedUser },
    { name: 'Reassigned By', value: UserType.ReassignedBy },
    { name: 'Validated By', value: UserType.ValidatedBy },
    { name: 'Audited By', value: UserType.AuditedBy },
  ];
  mobileUserType: UserType[] = [
    UserType.FirstCapturedUser,
    UserType.LastCapturedUser,
  ];
  adminUserType: UserType[] = [
    UserType.ValidatedBy,
    UserType.ReassignedBy,
    UserType.AuditedBy,
  ];
  
  dtId?: number;
  dtName?: string;

  constructor(
    private poleService: PoleService,
    private toastr: ToastrService,
    public permissionsService: PermissionsService,
    private regionService: RegionService,
    private areaService: AreaService,
    private translationService: TranslationService,
    private route: ActivatedRoute,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private router: Router    

  ) {}

  ngOnInit(): void {
    if (!this.setDtFilter()) return; // Set Filter for Dt if page is navigated from DT page
    this.utilityId = parseInt(localStorage.getItem('utilityId') || '');
    this.setFilters();
    this.getRegions();
    this.setCheckedUsers();
    this.getData();
    this.subscribeToClickEvent();
    this.searchTextUpdate.pipe(debounceTime(500)).subscribe((value) => {
      if (this.searchText == '') this.search();
    });

    this.selectedDateType = this.selectedDateType ?? this.dateTypes[0].name;
    this.selectedDateTypeDisplay =
      this.selectedDateTypeDisplay ?? this.dateTypes[0].value;
  }
  ngOnDestroy(): void {
    this.searchTextUpdate.complete();
  }
  private getData(): void {
    this.getPolesStats();
    this.getPoleTypesForOptions();
    this.reloadTable();
  }
  
  setDtFilter(){
    var dtId, dtName;
    this.route.paramMap.subscribe(params => { //Get Values from url
      dtId = params.get('dtId');
      dtName = params.get('dtName');
    });
  
    if (dtId && dtName){ //Set Values from url to cache and reload page
      localStorage.setItem('filterDt',JSON.stringify({ type: 'Dt', filter: {dtId: dtId, dtName: dtName} }));
      this.router.navigate(['/poles']);
      return false;
    } 
  
    var filter = localStorage.getItem('filterDt'); // Set values from cache to variables and delete from cache 
    if (!filter) return true;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Dt') return true;
    this.dtId = filterObject.filter.dtId;
    this.dtName = filterObject.filter.dtName;
    localStorage.removeItem('filterDt');
  
    return true;
  }

  onFilterBy(option: any) {
    this.filterByValue = option;
    this.reloadTable(1);
  }

  setFilters() {
    var filter = localStorage.getItem('filterPole');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Pole') return;
    this.currentPage = filterObject.filter.pageInfo.page;
    this.pageSize = filterObject.filter.pageInfo.pageSize;
    this.count = filterObject.filter.pageInfo.count;
    this.sortByValue = filterObject.filter.filterParams.sortBy;
    this.searchText = filterObject.filter.filterParams.search;
    this.dateFrom = filterObject.filter.filterParams.dateFrom;
    this.dateTo = filterObject.filter.filterParams.dateTo;
    this.selectedDateType = filterObject.filter.filterParams.dateType;
    this.selectedDateTypeDisplay =
      this.dateTypes.find(
        (type) => type.name == filterObject.filter.filterParams.dateType
      )?.value ?? this.dateTypes[0].value;
    this.poleStatus = filterObject.filter.filterParams.status;
    this.poleType = filterObject.filter.filterParams.type;
    this.poleCondition = filterObject.filter.filterParams.condition;
    this.selectedAreas = filterObject.filter.filterParams.selectedAreas
      ? filterObject.filter.filterParams.selectedAreas
      : [];
    this.selectedRegions = filterObject.filter.filterParams.selectedRegions
      ? filterObject.filter.filterParams.selectedRegions
      : [];
      this.selectedUsers = filterObject.filter.filterParams.selectedUsers
    ? filterObject.filter.filterParams.selectedUsers
    : [];
    this.sortByName = this.mapSortBy[this.sortByValue];
    this.dtId = filterObject.filter.filterParams.dtId
      ? filterObject.filter.filterParams.dtId
      : 0;
    this.dtName = filterObject.filter.filterParams.dtName
      ? filterObject.filter.filterParams.dtName
      : undefined;
  }
  private getRegions() {
    this.regionService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
          this.setCheckedRegions();
          this.getAreas();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.allAreas = responseData.data;
          this.areas = this.allAreas.filter((area) =>
          this.regions.some(
              (region) => region.id == area.regionId && region.checked
            )
          );
          this.setCheckedAreas();
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  getPoleTypesForOptions() {
    this.poleService.getPoleTypesForOptions().subscribe(
      (response) => {
        this.poleTypesOptions = response.data;
        this.createFilterCards();
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }
  private getPolesStats() {
    this.poleService.getBuildingsStats().subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats = data.data;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  setFilterStorage() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count
      },
      filterParams: this.getFilterObject(),
      utilityId: this.utilityId,
    };

    // Remove 'T23:59:59' from the dateTo to avoid errors
    if (obj.filterParams.dateTo) {
        obj.filterParams.dateTo = obj.filterParams.dateTo.replace('T23:59:59', '');
    }

    localStorage.setItem(
      'filterPole',
      JSON.stringify({ type: 'Pole', filter: obj })
    );
  }
  getFilterObject() {
    let obj = {
      sortBy: this.sortByValue,
      search: this.searchText,
      filterBy: this.filterByValue,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
      dateType: this.selectedDateType,
      selectedRegions: this.selectedRegions,
      selectedAreas: this.selectedAreas,
      userType: this.userType
      ? this.selectedUsers.length > 0
        ? this.userType
        : null
      : null,
      selectedUsers: this.selectedUsers.length > 0 ? this.selectedUsers : null,
      type: this.poleType,
      condition: this.poleCondition,
      status: this.poleStatus,
      selectedIds: this.poles
        .filter((x) => x.isChecked == true)
        .map((x) => x.id),
      dtId: this.dtId ? Number(this.dtId) : null,
      dtName: this.dtName ? this.dtName : null,
    };
    return obj;
  }
  datesValid() {
    if (this.dateFrom && this.dateTo && this.dateTo?.toString() != '') {
        const valid: boolean = new Date(this.dateFrom).getTime() < new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
        return valid;
    }
    return true;
  }
  private getAll(obj: any) {
    this.poleService.getAll(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.poles = responseData?.data?.data?.length ? responseData?.data?.data?.map((el: any) => new PoleDetails(el)) : [];
          this.poles.forEach(x => {
            x.buildingsSLRN.replace(/,/g, ', ');
          });

          this.count = responseData.data.count ?? this.count;
          this.createFilterCards();
          this.removeFilterStorage();
          if (responseData.message != '') {
            this.toastr.warning(responseData.message);
          }
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  isAllCheckBoxChecked() {
    return false;
  }

  checkAllCheckboxes() {
    this.poles.forEach((x) => (x.isChecked = !this.allCheckboxesChecked));
    this.allCheckboxesChecked = !this.allCheckboxesChecked;
  }

  checkPole(id: number) {
    const pole = this.poles.find((x) => x.id === id);
    if (pole) {
      pole.isChecked = !pole.isChecked;
      this.areAllCheckboxesChecked();
    }
  }

  areAllCheckboxesChecked() {
    this.allCheckboxesChecked = this.poles.every((x) => x.isChecked);
  }

  reloadTable(page: any = null) {
    this.allCheckboxesChecked = false;
    if (page) this.currentPage = page;
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count
      },
      filterParams: this.getFilterObject(),
    };

    this.analyticsService.logCustomEvent('POLES: Filter list', {
      page: obj.pageInfo.page,
      sort_by: obj.filterParams.sortBy,
      search: obj.filterParams.search,
      filter_card: obj.filterParams.filterBy,
      user_type: obj.filterParams.userType,
      date_from: obj.filterParams.dateFrom,
      date_to: obj.filterParams.dateTo,
      users: obj.filterParams.selectedUsers,
      date_type: obj.filterParams.dateType,
      status: obj.filterParams.status,
      condition: obj.filterParams.condition,
      poleType: obj.filterParams.type,
      areas: obj.filterParams.selectedAreas,
      regions: obj.filterParams.selectedRegions,
      dtId: obj.filterParams.dtId,
    });

    this.getAll(obj);
  }

  search() {
    this.reloadTable(1);
  }

  sortBy(option: SortByOptions) {
    this.sortByValue = option;
    this.sortByName = this.mapSortBy[option];
    this.reloadTable();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  private subscribeToClickEvent() {
    this.clickObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((e: any) => {
        this.showUpload = false;
        this.showSortBy = false;
        this.showSelectDateType = false;
      });
  }

  download() {
    let obj = this.getFilterObject();

    this.analyticsService.logCustomEvent('POLES: Download', {
      sort_by: obj.sortBy,
      search: obj.search,
      filter_card: obj.filterBy,
      user_type: obj.userType,
      date_from: obj.dateFrom,
      date_to: obj.dateTo,
      users: obj.selectedUsers,
      date_type: obj.dateType,
      status: obj.status,
      condition: obj.condition,
      pole_type: obj.type,
      areas: obj.selectedAreas,
      regions: obj.selectedRegions,
      dtId: obj.dtId,
    });
  
    this.poleService.download(obj).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else if (data?.status === 'Pending' || data?.status === '600') {
          this.toastr.info(data?.message || '');
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  viewHistory(id: number) {
    this.poleService.getHistory(id).subscribe({
      next: (response) => {
        this.historyItems = response?.body?.data ?? [];
      },
    });
  }

  selectRegion(e: any) {
    //list of areas for selected regions
    this.areas = this.allAreas.filter((area) =>
      this.regions.some(
        (region) => region.id == area.regionId && region.checked
      )
    );
    this.allAreas.forEach((area) => {
      if (!this.areas.some((a) => a.id === area.id)) {
        area.checked = false; //uncheck areas for unchecked regions
      }
    });

    this.createFilterCards();
  }
  selectArea(e: any) {
    this.createFilterCards();
  }
  selectPoleType(e: any) {
    this.poleType = +e.target.value;
    this.createFilterCards();
    this.reloadTable(1);
  }
  selectPoleCondition(e: any) {
    this.poleCondition = +e.target.value;
    this.createFilterCards();
    this.reloadTable(1);
  }
  selectPoleStatus(e: any) {
    this.poleStatus = +e.target.value;
    this.createFilterCards();
    this.reloadTable(1);
  }
  createFilterCards() {
    this.selectedFilters = [];
    let selectedRegions = this.regions.filter((x) => x.checked);
    let selectedUsers = this.allUsers.filter((x) => x.checked);
    let selectedAreas = this.areas.filter((x) => x.checked);
    this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
    this.selectedRegions = this.regions
      .filter((x) => x.checked)
      .map((x) => x.id);
    this.selectedUsers = this.allUsers
      .filter((x) => x.checked)
      .map((x) => x.id);
    //regions
    for (var i = 0; i < selectedRegions.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.buName, selectedRegions[i].name)
      );
    }
    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.utName, selectedAreas[i].name)
      );
    }
    
    if (this.userType) {
      var userTypeName = UserType[this.userType!];
      this.selectedFilters.push(new ChecklistItem('User type', userTypeName));
    }

    //users
    for (var i = 0; i < selectedUsers.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem('User', selectedUsers[i].name)
      );
    }

    if (this.poleType != 0 && this.poleTypesOptions.length > 0) {
      let poleName = this.poleTypesOptions.filter(
        (x) => x.key === this.poleType
      )[0].value;
      this.selectedFilters.push(new ChecklistItem('Pole Type', poleName));
    }

    if (this.poleCondition != 0) {
      let poleCondition = this.poleConditionOptions.filter(
        (x) => x.value == this.poleCondition
      )[0].name;
      this.selectedFilters.push(
        new ChecklistItem('Pole Condition', poleCondition)
      );
    }
    if (this.poleStatus != 0) {
      let poleStatus = this.poleStatusOptions.filter(
        (x) => x.value == this.poleStatus
      )[0].name;
      this.selectedFilters.push(new ChecklistItem('Pole Status', poleStatus));
    }
    
    if (this.dtId && this.dtName) { //dt from transformers
      this.selectedFilters.push(new ChecklistItem('Dt Name', this.dtName));
    }
  }
  removeFilter(item: ChecklistItem) {
    if (item.property == this.buName) {
      this.regions.forEach((region) => {
        if (region.name === item.selectedValue) {
          region.checked = false;
        }
      });
      this.selectRegion(event);
      this.clicked = !this.clicked;
    } else if (item.property == 'User') {
      this.allUsers.forEach((user) => {
        if (user.name === item.selectedValue) {
          user.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'User type') {
      this.userType = undefined;
      this.allUsers.forEach((user) => {
        // Clear list if he selects same filter again to get all cleared
        user.checked = false;
      });
      this.allUsers = this.filteredUsers = [...[]];
    } else if (item.property == this.utName) {
      this.areas.forEach((area) => {
        if (area.name === item.selectedValue) {
          area.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'Pole Type') {
      this.poleType = 0;
    } else if (item.property == 'Pole Condition') {
      this.poleCondition = 0;
    } else if (item.property == 'Pole Status') {
      this.poleStatus = 0;
    } else if(item.property == 'Dt Name'){
      this.dtId = 0;
      this.dtName = 'undefined';
    }

    this.createFilterCards();
    this.reloadTable(1);
  }

  resetFilter(e: any) {
    this.selectedFilters = [];
    this.regions.forEach((region) => {
      region.checked = false;
    });
    this.areas.forEach((area) => {
      area.checked = false;
    });
    this.areas = [];
    this.allUsers.forEach((user) => {
      user.checked = false;
    });
    this.allUsers = this.filteredUsers = [...[]];
    this.selectedAreas = [];
    this.selectedRegions = [];
    this.userType = undefined;
    this.poleType = 0;
    this.poleCondition = 0;
    this.poleStatus = 0;
    this.clicked = !this.clicked;
    
    this.dtId = undefined;
    this.dtName = undefined;

    this.reloadTable(1);
  }
  setCheckedAreas() {
    var filter = localStorage.getItem('filterPole');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Pole') return;
    if (filterObject.filter.filterParams.selectedAreas) {
      this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
      this.areas.forEach((element) => {
        if (this.selectedAreas.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
    this.createFilterCards();
    this.removeFilterStorage();
  }
  setCheckedRegions() {
    var filter = localStorage.getItem('filterPole');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Pole') return;
    if (filterObject.filter.filterParams.selectedRegions) {
      this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
      this.regions.forEach((element) => {
        if (this.selectedRegions.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
    this.createFilterCards();
    this.removeFilterStorage();
  }

  removeFilterStorage() {
    if (this.allAreas.length > 0 && this.regions.length > 0)
      localStorage.removeItem('filterPole');
  }

  selectDateType(dateType: any){
    this.selectedDateType = dateType.name;
    this.selectedDateTypeDisplay = dateType.value;
    this.showSelectDateType = false;
  }

  formatSlrn(originalString: string): string {
    return originalString.split(",", 2).toString().replace(/,/g, ', ');
  }

  async setCheckedUsers() {
    var filter = localStorage.getItem('filterPole');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Pole') return;
    if (
      filterObject.filter.filterParams.selectedUsers &&
      filterObject.filter.filterParams.userType
    ) {
      this.userType = filterObject.filter.filterParams.userType;
      this.getUsersForFilter(true, filterObject);
    }
  }
  
  resetUserList() {
    this.allUsers.forEach((user) => {
      user.checked = false;
    });
    this.allUsers = [];
  }

  selectUserType(e: any) {
    if (this.userType)
      this.resetUserList();

    if (e.target.value != '') {
      this.userType = e.target.value;
      this.getUsersForFilter(false);
    } else {
      this.userType = undefined;
    }

    this.createFilterCards();
  }

  getUsersForFilter(isFromCache: boolean, filterObject?: any) {
    if (
      this.userType == UserType.FirstCapturedUser ||
      this.userType == UserType.LastCapturedUser
    ) {
      // Dobavi mobilne usere iz baze ako nisi
      if (this.mobileUsers.length === 0) {
        this.userService
          .getUsersForFilter(SystemComponents.Mobile)
          .subscribe((resp) => {
            this.mobileUsers = resp.data;
            this.allUsers = this.filteredUsers = this.mobileUsers;
            if (isFromCache) {
              this.selectedUsers =
                filterObject.filter.filterParams.selectedUsers;
              this.allUsers.forEach((element) => {
                if (this.selectedUsers.includes(element.id))
                  element.checked = true;
                else element.checked = false;
              });
            }
            this.createFilterCards();
          });
      } else {
        this.allUsers = this.filteredUsers = [...this.mobileUsers];
      }
    } else {
      if (this.adminUsers.length === 0) {
        this.userService
          .getUsersForFilter(SystemComponents.Admin)
          .subscribe((resp) => {
            this.adminUsers = resp.data;
            this.allUsers = this.filteredUsers = this.adminUsers;
            if (isFromCache) {
              this.selectedUsers =
                filterObject.filter.filterParams.selectedUsers;
              this.allUsers.forEach((element) => {
                if (this.selectedUsers.includes(element.id))
                  element.checked = true;
                else element.checked = false;
              });
              this.createFilterCards();
            }
          });
      } else {
        this.allUsers = this.filteredUsers = [...this.adminUsers];
      }
    }
  }

  applySearchFilter(e: any) {
    this.filteredUsers = this.allUsers.filter((user) => user.name.includes(e));
  }

  selectUsers(e: any) {
    if (e.status) {
      this.allUsers.push(e.value);
    } else {
      var index = this.allUsers.indexOf(e.value);
      this.allUsers.splice(index, 1);
    }
    this.createFilterCards();
  }
}
